.pdf-download-icon-span {
  width: 21px;
}

.pdf-download-icon {
  color: green;
  font-size: 15px;
  font-weight: 800;
  margin-left: 5px;
  cursor: pointer;
}

.pending-verification-link-icon{
  color: #146EBE;
  cursor: pointer;
}